// $(document).ready(function() {
//     customCookieBar();
// })

// function customCookieBar() {
//     if ( $('.cookies-bar').length > 0 ) {
//         if ( !Cookies.get('accepted_cookies') ) {
//             $('.cookies-bar').fadeIn();
//         }
        
//         $('.cookies-button').on('click', function () {
//             Cookies.set('accepted_cookies', 'true', {expires: $('#cookies-bar').data('notice-keep-hidden-for-n-days')});
//             $('.cookies-bar').css('display', 'none')
//         });
//     }
// }